
const SCHOOL_ID = 'school_id'

export function getSchoolId() {
  return localStorage.getItem(SCHOOL_ID) ? parseInt(localStorage.getItem(SCHOOL_ID)) : ''
}

export function setSchoolId(school_id) {
  return localStorage.setItem(SCHOOL_ID, school_id)
}

export function removeSchoolId() {
  return localStorage.removeItem(SCHOOL_ID)
}

