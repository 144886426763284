<template>
  <el-menu class="navbar" mode="horizontal">
    <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container" />
    <breadcrumb />
    <div class="school-selecter">
      <el-select size="small" v-model="school_id" placeholder="请选择学校">
        <el-option v-for="item in schools" :key="item.id" :label="item.school_name" :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-dropdown class="avatar-container" trigger="click">
      <div class="avatar-wrapper">
        <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar"> -->
        <!-- <img src="@/assets/avatar.jpeg" class="user-avatar"> -->
        <img v-if="logoURL" :src="logoURL" class="user-avatar" />
        <i class="el-icon-caret-bottom" />
      </div>
      <el-dropdown-menu slot="dropdown" class="user-dropdown">
        <el-dropdown-item disabled>
          {{ username }}
        </el-dropdown-item>
        <router-link class="inlineBlock" to="/info">
          <el-dropdown-item divided>
            修改密码
          </el-dropdown-item>
        </router-link>
        <el-dropdown-item>
          <span style="display:block;" @click="logout">退出登录</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "username", "schools"]),
    school_id: {
      get() {
        return this.$store.state.user.school_id;
      },
      set(newVal) {
        this.$store.dispatch("SetSchoolId", newVal);
      }
    },
    logoURL() {
      // 变量直接 return '@/assets/shegou_logo.png' 静态资源不会被 webpack 打包
      // 所以要require进来才能正常显示
      return require(process.env.VUE_APP_LOGO)
      // return require('@/assets/shegou_logo.png')
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      this.$store.dispatch("FedLogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    }
  }
};
</script>

<style lang="scss">
.school-selecter {
  top: 5px;
  position: absolute;
  right: 110px;
  display: inline-block;
  align-items: center;
  .el-select {
    background: #edf7fe;
    border-left: 5px solid #63bafa;
    border-radius: 5px;
    overflow: hidden;
    .el-input {
      height: 40px;
      line-height: 40px;
    }
    input {
      border: 0;
      background: transparent;
    }
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
  // .school-selecter {
  //   height: 50px;
  //   position: absolute;
  //   right: 125px;
  // }
}
</style>
