import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getSchoolId, setSchoolId, removeSchoolId } from '@/utils/school'
import request from "@/utils/request";

const user = {
  state: {
    token: getToken(),
    username: '',
    avatar: '',
    roles: [],
    permissions: [],
    user: {},
    schools: [],
    school_id: getSchoolId(),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, username) => {
      state.username = username
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_SCHOOLS: (state, schools) => {
      state.schools = schools
    },
    SET_SCHOOL_ID: (state, school_id) => {
      state.school_id = school_id
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password).then(response => {
          const data = response.data
          setToken(data.token, userInfo.keepLogin)
          commit('SET_TOKEN', data.token)
          commit('SET_NAME', data.user.username)
          commit('SET_USER', data.user)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          const data = response.data
          if (data.permissions && data.permissions.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_NAME', data.user.username)
            commit('SET_PERMISSIONS', data.permissions)
            commit('SET_USER', data.user)
            console.log(data.user);
          } else {
            reject('抱歉，您无权限访问');
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetSchools({ commit }) {
      return new Promise((resolve, reject) => {
        return request({
          url: '/api/backend/school/list',
          method: 'get',
          params: {
            page: 1,
            limit: 10000,
          }
        }).then(response => {
          let schools = response.data.data
          let school_id = getSchoolId()
          let matchs = schools.filter((element) => {
            return element.id == school_id
          })
          commit('SET_SCHOOLS', schools)
          if (matchs.length <= 0) {
            // 学校已被删除，默认选择第一个或清空
            commit('SET_SCHOOL_ID', schools.length > 0 ? schools[0].id : 0)
          }
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },

    SetSchoolId({ commit }, school_id) {
      commit('SET_SCHOOL_ID', school_id)
      setSchoolId(school_id)
    },

    // 登出
    // LogOut({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     logout(state.token).then(() => {
    //       commit('SET_TOKEN', '')
    //       commit('SET_ROLES', [])
    //       removeToken()
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_PERMISSIONS', [])
        removeToken()
        resolve()
      })
    }
  }
}

export default user
